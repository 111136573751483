import { isGroupOrder } from "models/groupOrder/GroupOrder";
import { OrderUpsellResponse } from "models/order/OrderUpsell";
import { useCallback, useEffect, useRef, useState } from "react";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";
import { getStoredGroupOrderParticipantName } from "util/GroupOrderParticipantNameUtils";

const useOrderUpsell = () => {
  const [orderUpsellResponse, setOrderUpsellResponse] = useState<OrderUpsellResponse>();
  const hasFetched = useRef(false);

  const { getOrderUpsell, addToOrder, order } = useOrder();
  const { user } = useUser();
  const basketId = useRef<string | undefined>();

  const loadOrderUpsell = useCallback(async () => {
    try {
      hasFetched.current = true;
      const response = await getOrderUpsell();
      setOrderUpsellResponse(response);

      // update the reference basket id so we know when to refetch
      basketId.current = order?.id;
    } catch {
      hasFetched.current = false;
      setOrderUpsellResponse(undefined);
    }
  }, [getOrderUpsell, order?.id]);

  useEffect(() => {
    // if the basket id has changed, we need to refetch upsells
    if (order?.id !== basketId.current) {
      hasFetched.current = false;
    }

    // only fetch when there's an order and if app has not fetched upsell yet
    if (order && order.items.length && !hasFetched.current) {
      loadOrderUpsell();
    }
  }, [order, loadOrderUpsell]);

  return {
    order: order,
    orderUpsellResponse,
    loadOrderUpsell,
    addUpsellToOrder: async (id: string, quantity: number) => {
      let recipientName: string | undefined;

      if (isGroupOrder(order)) {
        if (user) {
          recipientName = `${user.firstName} ${user.lastName}`;
        } else {
          recipientName = getStoredGroupOrderParticipantName();
        }
      }
      await addToOrder(id, quantity, undefined, undefined, recipientName, true);
    },
  };
};

export default useOrderUpsell;

import "@splidejs/react-splide/css";
import { Box, SxProps, Theme } from "@mui/material";
import { Options, Splide, SplideSlide } from "@splidejs/react-splide";
import { PropsWithChildren } from "react";
import styles from "ui/components/Carousel/Carousel.styles";
import { defaultOptions, handleVisible } from "util/SlideComponent";

type CarouselProps = {
  label: string;
  options?: Options;
  onImpression?: (productName?: string | null) => void;
  onScroll?: () => void;
  sx?: SxProps<Theme>;
} & PropsWithChildren;

const Carousel = ({ label, options = {}, onImpression, onScroll, children, ...props }: CarouselProps) => {
  const mergedOptions: Options = {
    ...defaultOptions,
    ...options,
    omitEnd: true,
    focus: 0,
  };

  const { sx, ...splideProps } = props;

  return (
    // this `sx` syntax is weird but is explained here: https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
    <Box sx={[styles.container, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box
        component={Splide}
        role="region"
        options={mergedOptions}
        aria-label={label}
        onVisible={(_, slideComponent) => handleVisible(slideComponent, onImpression)}
        onMoved={onScroll}
        {...splideProps}
      >
        {children}
      </Box>
    </Box>
  );
};

export const CarouselItem = SplideSlide;

export default Carousel;

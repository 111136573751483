import MuiStyles from "ui/util/Styles";

const styles: MuiStyles = {
  container: (theme) => ({
    alignItems: "center",
    display: "inline-flex",
    gap: "0.5rem",
    padding: "0.625rem 0.75rem",
    textDecoration: "none",

    "&:hover": {
      color: theme.palette.secondary.contrastText,
    },

    "&:focus-visible": {
      outline: `0.25rem solid ${theme.palette.primary.light}`,
    },

    "@supports not selector(:focus-visible)": {
      "&:focus": {
        outline: `0.25rem solid ${theme.palette.primary.light}`,
      },
    },

    [theme.breakpoints.up("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  }),

  label: {
    typography: "globalNav",

    alignItems: "center",
    color: (theme) => theme.palette.secondary.contrastText,
    display: "flex",
    gap: "0.5rem",
    height: "2.25rem",
    paddingTop: "0.375rem",
    whiteSpace: "nowrap",
  },
  bottomDivider: {
    borderBottomWidth: "0.125rem",
    marginTop: "0.125rem",
    marginBottom: "0.125rem",
    transform: "scaleX(0)",

    ".global-nav-link:hover &": {
      borderColor: (theme) => theme.palette.grey[500],
      transform: "scaleX(1)",
      transition:
        "border-color 200ms, border-bottom-width 400ms cubic-bezier(0.5,0,0.5,3), margin-bottom 400ms cubic-bezier(0.5,0,0.5,3), transform 200ms ease",
    },

    ".global-nav-link.selected &": {
      borderColor: (theme) => theme.palette.primary.main,
      borderBottomWidth: "0.25rem",
      marginBottom: "0",
      transform: "scaleX(1)",
    },

    ".global-nav-link:active &": {
      borderColor: (theme) => theme.palette.primary.main,
      transform: "scaleX(1)",
    },
  },
};

export default styles;

import { Box, Divider, IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import logoSrc from "assets/logo.svg";
import useAnalytics from "hooks/useAnalytics";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { Link, useMatch } from "react-router-dom";
import styles from "ui/app/GlobalNav/GlobalNav.styles";
import useViewModel from "ui/app/GlobalNav/GlobalNavViewModel";
import NavCart from "ui/app/GlobalNav/NavCart";
import NavDrawer from "ui/app/GlobalNav/NavDrawer";
import NavUser from "ui/app/GlobalNav/NavUser";
import OrderNav from "ui/app/GlobalNav/OrderNav";
import Cart from "ui/components/Cart";
import GlobalNavLink from "ui/components/GlobalNavLink/GlobalNavLink";
import Icon from "ui/components/Icon/Icon";
import { CheckoutPagePath, MainPagePath } from "ui/navigation/Pages";

const GlobalNav = observer(() => {
  const { cartItemCount, hideDrawer, shouldShowCart, shouldShowDrawer, showDrawer, toggleCart, hasOrder } =
    useViewModel();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isCheckoutRoute = useMatch(CheckoutPagePath.checkout) != null;
  const navRef = useRef<HTMLElement>(null);
  const { analytics } = useAnalytics();
  const onClickNavigation = (linkText: string) => analytics.linkClick(linkText, "Global Header");

  return (
    <>
      <Box color="secondary" component="nav" sx={styles.appBar}>
        <Box sx={styles.toolBar} ref={navRef}>
          <Stack direction="row">
            <IconButton
              onClick={showDrawer}
              size="large"
              edge="start"
              color="secondary"
              aria-expanded={shouldShowDrawer}
              aria-label={shouldShowDrawer ? "Close the navigation menu" : "Open the navigation menu"}
              sx={styles.openDrawerButton}
            >
              <Icon icon="hamburger" />
            </IconButton>
            <Box component={Link} to={MainPagePath.home} sx={styles.logo} onClick={() => onClickNavigation("Home")}>
              <img alt="Jimmy John's - Home" src={logoSrc} />
            </Box>
          </Stack>
          {!isCheckoutRoute ? (
            <Stack direction="row" sx={styles.navItems} component="ul">
              <Box component="li" sx={styles.navItem}>
                <GlobalNavLink to={MainPagePath.menu} selectOnMatch onClick={() => onClickNavigation("Menu")}>
                  Menu
                </GlobalNavLink>
                {isDesktop && <Icon icon="star" />}
              </Box>
              <Box component="li" sx={styles.navItem}>
                <GlobalNavLink to={MainPagePath.catering} selectOnMatch onClick={() => onClickNavigation("Catering")}>
                  Catering
                </GlobalNavLink>
                {isDesktop && <Icon icon="star" />}
              </Box>
              <Box component="li" sx={styles.navItem}>
                <GlobalNavLink to={MainPagePath.locations} selectOnMatch onClick={() => onClickNavigation("Locations")}>
                  Locations
                </GlobalNavLink>
                {isDesktop && <Icon icon="star" />}
              </Box>
              <Box component="li" sx={styles.navItem}>
                <GlobalNavLink to={MainPagePath.rewards} selectOnMatch onClick={() => onClickNavigation("Rewards")}>
                  Rewards
                </GlobalNavLink>
              </Box>
            </Stack>
          ) : null}
          <Stack direction="row" sx={styles.navControls}>
            <NavUser navRef={navRef} />
            <Divider orientation="vertical" variant="middle" sx={styles.divider} flexItem aria-hidden="true" />
            {!isCheckoutRoute && ((isTablet && !hasOrder) || isDesktop) && <OrderNav />}
            <NavCart
              itemCount={cartItemCount}
              onClick={() => {
                toggleCart();
                analytics.linkClick("Toggle Cart", "Global Header");
              }}
            />
          </Stack>
        </Box>

        {!isCheckoutRoute && ((!isDesktop && hasOrder) || isMobile) && <OrderNav />}
      </Box>
      <NavDrawer hideDrawer={hideDrawer} shouldShowDrawer={shouldShowDrawer} />
      <Cart isOpen={shouldShowCart} onClose={toggleCart} />
    </>
  );
});

export default GlobalNav;

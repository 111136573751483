import AchievementBadge from "models/rewards/AchievementBadge";
import { useState } from "react";
import { setBadgesAsLocallySeen } from "stores/badgeModal/localBadgeState.helpers";
import { useUser } from "stores/user";

export type BadgeModalManager = {
  badges?: AchievementBadge[];
  hideBadgeModal: () => void;
  showBadgeModal: (badges: AchievementBadge[]) => void;
};

const useBadgeModalManager = (): BadgeModalManager => {
  const { updateAchievementBadgesAsSeen } = useUser();
  const [badges, setBadges] = useState<AchievementBadge[]>();

  const hideBadgeModal = () => setBadges(undefined);

  const showBadgeModal = (badges: AchievementBadge[]) => {
    setBadges(badges);
    setBadgesAsLocallySeen(badges);
    updateAchievementBadgesAsSeen(badges);
  };

  return {
    badges,
    hideBadgeModal,
    showBadgeModal,
  };
};

export default useBadgeModalManager;

/**
 * Error messages used for form validation
 * Copy comes from: https://docs.google.com/spreadsheets/d/1TIvav3b3W92AccqDOMvQT6ocWyPqf_x7IvgDOGJfr1Y/edit#gid=0
 */
const ValidationErrorMessages = {
  Default: {
    FieldIsRequired: "This field is required.",
  },

  Address: {
    DoesNotMeetCharacterRequirements: "The value entered contains unsupported characters.",
    DoesNotMeetBeginningRequirements: "Value must begin with a letter or number.",
    DoesNotMeetEndRequirements: "Value must end with with a letter, number, period, or apostrophe.",
    InvalidZipCode: "Please enter a valid zip code",
    InvalidUsShortZipCode: "Zip Code must be a 5 digit number.",
  },
  Birthday: {
    InvalidBirthday: "You did not enter a valid birthday using the format MM/DD",
  },
  CustomTipAmount: {
    NegativeAmount: "The amount entered is not valid, please try a different amount.",
    TooLarge: "The amount entered is too large, please try a different amount.",
  },
  Email: {
    DoesNotMeetLengthRequirements: "Your email is too long, please use another.",
    DoesNotMeetCharacterRequirements: "Your email contains unsupported characters, please use another.",
    DoesNotHaveAtSymbol: "Your email is invalid, it must contain an @.",
    DoesNotMeetLocalRequirements: "Your email has an invalid length, please use another.",
    DoesNotMeetDomainRequirements: "Your email domain contains invalid characters.",
    InvalidEmail: "A valid email address is required",
  },
  Name: {
    DoesNotMeetCharacterRequirements: "The name entered contains unsupported characters.",
    DoesNotMeetLengthRequirements: "The name entered is too long. Max 30 characters.",
    DoesNotMeetBeginningEndRequirements: "Names must begin and end with a letter.",
  },
  PhoneNumber: {
    DoesNotMeetLengthRequirements: "Phone numbers must contain a 3 digit area code and 7 digit number.",
    DoesNotMeetCharacterRequirements: "The phone number entered contains unsupported characters.",
  },
  SavedPayment: {
    DoesNotMeetLengthRequirements: "Display name entered is too long. Max 30 characters.",
    DoesNotMeetCharacterRequirements: "The name entered contains unsupported characters.",
  },
  CreditCard: {
    DoesNotMeetSecurityCode: "Security Code must be a 3-4 digit number.",
    InvalidCardNumber: "Please enter a valid credit card number",
    InvalidExpiration: "You did not enter a valid Expiration using the format MM/YYYY",
  },
  TermsAndConditions: {
    PleaseAcceptTerms: "Please accept Terms and Conditions",
  },
};

export default ValidationErrorMessages;

import { Box, Button, Divider, Typography } from "@mui/material";
import useAnalytics from "hooks/useAnalytics";
import { useState } from "react";
import Carousel, { CarouselItem } from "ui/components/Carousel";
import { CartUpsellProduct } from "ui/components/Cart/CartViewModel";
import styles from "ui/components/CartUpsell/CartUpsell.styles";
import Image from "ui/components/Image";
import { Size } from "ui/Size";
import { Formatter } from "util/Formatter";
import { trackOptimizelyEvent } from "util/Optimizely";
import { OptimizelyEventName, OptimizelyTagName, PlacementOptions } from "util/Optimizely/OptimizelyTypes";

const CartUpsell = ({ cartUpsellProducts }: { cartUpsellProducts: CartUpsellProduct[] | undefined }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { analytics } = useAnalytics();

  if (!cartUpsellProducts || !cartUpsellProducts.length) {
    return null;
  }

  const groupNameInfo = "mobile-customize";

  const onImpression = (productName?: string | null) => {
    if (!productName) return;

    analytics.upsellImpression(productName);
  };

  const onScroll = () => {
    analytics.upsellScroll();
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="cardTitle" sx={styles.headerTitle}>
          Recommended For{" "}
          <Box component="span" sx={styles.headerTitleCursive}>
            You
          </Box>
        </Typography>
      </Box>
      <Carousel
        options={{ arrows: false, rewind: true }}
        label="Recommended for you"
        onImpression={onImpression}
        onScroll={onScroll}
      >
        {cartUpsellProducts.map((product) => {
          const addProductToOrder = async () => {
            setIsDisabled(true);
            await product.addToOrder();
            analytics.upsellAdd(product.name);
            trackOptimizelyEvent(OptimizelyEventName.CROSS_SELL_CLICK, {
              [OptimizelyTagName.ITEM_NAME]: product.name,
              [OptimizelyTagName.PLACEMENT]: PlacementOptions.BAG,
            });
            setIsDisabled(false);
          };

          return (
            <Box component={CarouselItem} tabIndex={-1} sx={styles.slide} key={product.id}>
              <Box sx={styles.product}>
                <Box sx={styles.productInner}>
                  {product.images ? (
                    <Image
                      images={product.images ?? []}
                      groupNameInfo={groupNameInfo}
                      imageUnavailableProps={{ size: Size.small }}
                      productName={product.name}
                      sx={styles.productImage}
                    />
                  ) : null}
                  <Box sx={styles.productInfo}>
                    <Box>
                      <Typography variant="formFieldLabel" className="productName">
                        {product.name}
                      </Typography>
                      <Box sx={styles.productInfoMeta}>
                        <Typography component="span" variant="bodySmallBold">{`+${product.cost}`}</Typography>
                        {product.calorieInfo ? (
                          <>
                            <Divider orientation="vertical" flexItem />
                            <Typography component="span" variant="bodySmall">
                              {Formatter.formatCalories(product.calorieInfo)}
                            </Typography>
                          </>
                        ) : null}
                      </Box>
                    </Box>
                    <Button
                      variant="text"
                      sx={styles.productInfoButton}
                      onClick={addProductToOrder}
                      disabled={isDisabled}
                    >
                      Add to Cart
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default CartUpsell;

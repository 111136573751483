import MuiStyles from "ui/util/Styles";

const styles = {
  formControlLabel: (theme) => ({
    alignItems: "center",
    display: "flex",
    flexBasis: "100%",
    flexGrow: 1,
    gap: "0.75rem",
    margin: 0,
    padding: "1rem 0",

    "&.Mui-disabled": {
      ".MuiTypography-root": {
        color: theme.palette.grey[500],
      },
    },
  }),
  maxWidth: {
    maxWidth: "fit-content",
  },
  root: {
    alignItems: "center",
    display: "flex",
  },
  content: {
    display: "flex",
    flexWrap: "wrap",

    ".checkbox-outlined &": {
      flexDirection: "column",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: (theme) => ({
    flexShrink: 0,

    //main tool tip container
    ".MuiTooltip-tooltip": {
      backgroundColor: theme.palette.common.black,
      borderRadius: 0,
      width: "13.5rem",
    },
    //tool tip arrow
    ".MuiTooltip-arrow": {
      color: theme.palette.common.black,
    },
  }),
  icon: (theme) => ({
    marginLeft: theme.pxToRem(12),
    width: "fit-content",
    "&:focus-visible": {
      outline: `solid 3px ${theme.palette.primary.light}`,
      borderRadius: "50%",
    },
  }),
  tooltipText: (theme) => ({
    color: theme.palette.common.white,
    padding: theme.pxToRem(16),
  }),
  checkboxTitle: {
    marginRight: ".25rem",
  },
  capitalizeTitle: {
    textTransform: "capitalize",
  },
  sublabels: {
    display: "flex",
  },
  sublabel: {
    ".checkbox-basic &": {
      color: "grey.500",
    },
  },
  divider: (theme) => ({
    background: theme.palette.grey[200],
    height: "1.25rem",
    width: "0.0625rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  }),
  sublabelTwo: {
    color: "grey.500",
  },
  basic: (theme) => ({
    "&:hover": {
      ".checkbox-icon": {
        background: theme.palette.grey[100],
      },

      ".Mui-checked .checkbox-icon": {
        background: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
    },

    "&:active, &.Mui-active": {
      ".checkbox-icon": {
        borderColor: theme.palette.secondary.main,
      },

      ".Mui-checked .checkbox-icon": {
        borderColor: theme.palette.secondary.main,
      },
    },

    ".Mui-disabled": {
      ".checkbox-icon": {
        background: theme.palette.grey[100],
        borderColor: theme.palette.grey[300],
      },

      ".Mui-checked .checkbox-icon": {
        background: theme.palette.grey[300],
        borderColor: theme.palette.grey[300],
      },
    },
  }),
  outlined: (theme) => ({
    border: `0.0625rem solid ${theme.palette.grey[200]}`,
    boxShadow: "0 0 0.25rem rgba(0, 0, 0, 0.25)",
    padding: "1.0625rem",

    "&:hover": {
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[400],
    },

    "&:active, &.Mui-active": {
      borderColor: theme.palette.secondary.main,
    },

    "&.Mui-disabled": {
      background: theme.palette.common.white,
      borderColor: theme.palette.grey[200],
      boxShadow: "none",
    },

    "&.selected": {
      border: `0.125rem solid ${theme.palette.secondary.main}`,
      padding: "1rem",

      "&:active, &.Mui-active": {
        border: `0.0625rem solid ${theme.palette.secondary.main}`,
        padding: "1.0625rem",
      },

      "&.Mui-disabled": {
        background: theme.palette.common.white,
        border: `0.0625rem solid ${theme.palette.grey[200]}`,
        boxShadow: "none",
        padding: "1.0625rem",
      },
    },
  }),
} satisfies MuiStyles;

export default styles;

import { SelectChangeEvent } from "@mui/material";
import { OrderType } from "models/order/OrderType";
import OrderAheadDateTimes from "models/store/OrderAheadDateTimes";
import { useEffect, useMemo, useState } from "react";
import {
  createTimeZoneHelperText,
  getBrowserTimeZoneOffset,
  getDayForTimeValue,
  getOrderTimeUnvailableError,
  getTimeOptionsForDay,
} from "ui/components/OrderTimePicker/OrderTimePicker.helpers";

export type OrderTimePickerOption = {
  label: string;
  value: string;
};

export type OrderTimePickerViewModelProps = {
  onChange: (timeWanted: string) => void;
  orderAheadDateTimes: OrderAheadDateTimes[];
  orderType: OrderType;
  storeTimeZoneOffset: string;
  value?: string;
  browserTimeZoneOffset?: string;
};

const useOrderTimePickerViewModel = ({
  browserTimeZoneOffset = getBrowserTimeZoneOffset(),
  onChange,
  orderAheadDateTimes,
  orderType,
  storeTimeZoneOffset,
  value = "",
}: OrderTimePickerViewModelProps) => {
  const [error, setError] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [timeOptions, setTimeOptions] = useState<OrderTimePickerOption[]>([]);
  const [timeZoneHelperText, setTimeZoneHelperText] = useState<string>();

  const dateOptions: OrderTimePickerOption[] = useMemo(() => {
    return orderAheadDateTimes.map(({ text }) => ({ label: text, value: text }));
  }, [orderAheadDateTimes]);

  const onDateChange = (event: SelectChangeEvent<unknown>) => {
    setError(undefined);

    // update selected day
    const selectedDate = event.target.value as string;
    setSelectedDate(selectedDate);

    // reset the time select state to avoid selecting a time that is no longer available
    setSelectedTime("");

    // update time options for the newly selected day
    const timeOptions = getTimeOptionsForDay(selectedDate, orderAheadDateTimes);
    setTimeOptions(timeOptions);

    // select the first available time for the newly selected day
    const firstAvailableTimeForSelectedDay = timeOptions[0]?.value;

    if (!firstAvailableTimeForSelectedDay) {
      setSelectedTime("");
    }

    setSelectedTime(firstAvailableTimeForSelectedDay);
  };

  const onTimeChange = (event: SelectChangeEvent<unknown>) => {
    setError(undefined);

    const selectedTime = event.target.value as string;
    setSelectedTime(selectedTime);
  };

  useEffect(() => {
    onChange(selectedTime);
  }, [onChange, selectedTime]);

  // date & time select state management
  useEffect(() => {
    if (orderAheadDateTimes.length === 0 || !value) return;

    // lookup the day for the currently selected time
    const day = getDayForTimeValue(orderAheadDateTimes, value);

    if (!day) return;

    const timeOptions = getTimeOptionsForDay(day, orderAheadDateTimes);
    setTimeOptions(timeOptions);

    setSelectedDate(day);
    setSelectedTime(value);
  }, [orderAheadDateTimes, value]);

  // current time wanted validation
  useEffect(() => {
    if (orderAheadDateTimes.length === 0 || !value) return;

    const isValidOrderTime = orderAheadDateTimes.some(({ timeSlots }) => {
      return timeSlots.some((timeSlot) => timeSlot.value === value);
    });

    if (isValidOrderTime) return;

    // if selected time slot is no longer valid; display invalid time error
    setError(getOrderTimeUnvailableError(orderType));

    // then, attempt to patch order to select the first valid time slot
    const firstAvailableTime = orderAheadDateTimes[0].timeSlots[0].value;
    setSelectedTime(firstAvailableTime);
  }, [orderAheadDateTimes, orderType, value]);

  // clear any previous "order time unavailable for order type" error when order type is changed
  useEffect(() => {
    setError(undefined);
  }, [orderType]);

  // determine if the time zone helper text should be displayed
  useEffect(() => {
    setTimeZoneHelperText(undefined);

    if (browserTimeZoneOffset === storeTimeZoneOffset || !selectedTime || selectedTime.toUpperCase() === "ASAP") return;

    const timeZoneHelperText = createTimeZoneHelperText(selectedTime);

    setTimeZoneHelperText(timeZoneHelperText);
  }, [browserTimeZoneOffset, selectedTime, storeTimeZoneOffset]);

  return {
    dateOptions,
    error,
    onDateChange,
    onTimeChange,
    selectedDate,
    selectedTime,
    timeOptions,
    timeZoneHelperText,
  };
};

export default useOrderTimePickerViewModel;

import { fontFamilyBlankenhorn } from "ui/theme/theme.typography";
import MuiStyles from "ui/util/Styles";

const styles = {
  container: {
    marginBottom: "1.5rem",
    padding: "0 0 0 0",

    "& .splide .splide__track": {
      width: "100%",
    },
  },
  header: {
    padding: "0.5rem 1rem",
  },
  headerTitle: (theme) => ({
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(20),
    },
  }),
  headerTitleCursive: (theme) => ({
    color: theme.palette.primary.main,
    fontFamily: fontFamilyBlankenhorn,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(24),
    textTransform: "lowercase",
  }),
  slide: {
    paddingRight: "1rem",
    width: "fit-content !important",
    willChange: "transform",
  },
  product: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    padding: "0.5rem 1rem",
    marginRight: "-2rem",
  },
  productInner: (theme) => ({
    border: `0.0625rem solid ${theme.palette.grey[200]}`,
    boxShadow: "0 0 0.25rem 0 rgba(0, 0, 0, 0.25)",
    display: "flex",
    flex: 1,
    gap: "0.75rem",
    padding: "1.25rem 1rem",
    width: "18rem",
  }),
  productImage: {
    alignSelf: "center",
    flexShrink: 0,
    height: "5rem",
    objectFit: "contain",
    width: "5rem",
  },
  productInfo: {
    alignItems: "flex-start",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  productInfoMeta: {
    display: "flex",
    gap: "0.5rem",
    marginBottom: "0.75rem",
  },
  productInfoButton: (theme) => ({
    typography: "bodySmall",

    [theme.breakpoints.up("md")]: {
      typography: "bodySmall",
    },
  }),
} as const satisfies MuiStyles;

export default styles;

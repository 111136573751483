import { PaymentType } from "models/payment/PaymentType";

/**
 * Saved Payment that comes back on an Order object.
 */
export type SavedPaymentInfoResponse = {
  type: PaymentType.savedPayment;
  details: {
    savedPaymentId: string;
  };
};

export const isSavedPaymentInfoResponse = (object: unknown): object is SavedPaymentInfoResponse => {
  const payment = object as SavedPaymentInfoResponse;
  return !!payment && !!payment.details && !!payment.details.savedPaymentId;
};

import { useMediaQuery, useTheme } from "@mui/material";
import { useBadgeModal } from "stores/badgeModal";
import useStoreManager from "stores/useStoreManager";
import { useFeatureFlag } from "util/Optimizely";
import FeatureFlags from "util/Optimizely/FeatureFlags";

const useLayoutViewModel = () => {
  const isAchievementBadgesEnabled = useFeatureFlag(FeatureFlags.achievementBadges);
  const { badges, hideBadgeModal } = useBadgeModal();
  const { isLoading } = useStoreManager();
  const theme = useTheme();
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up("md"));

  return {
    badges,
    hideBadgeModal,
    onCloseBadgeModal: hideBadgeModal,
    isAchievementBadgesEnabled,
    isLoading,
    isMediumOrLarger,
  };
};

export default useLayoutViewModel;

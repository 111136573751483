import AchievementBadge from "models/rewards/AchievementBadge";
import LocalStorage from "util/LocalStorage";

/**
 * Helpers for managing local "seen" state of achievement badges;
 * protects against network issues causing infinite modal display loops
 **/

const locallySeenBadgeStorage = new LocalStorage<string[]>("seen_badges");

export const setBadgesAsLocallySeen = (badges: AchievementBadge[]) => {
  const locallySeenBadges = locallySeenBadgeStorage.get() ?? [];
  const newSeenBadges = badges.filter(({ id }) => !locallySeenBadges.includes(id));
  const newSeenBadgeIDs = newSeenBadges.map(({ id }) => id);
  const seenBadgeIds = locallySeenBadges.concat(newSeenBadgeIDs);

  locallySeenBadgeStorage.set(seenBadgeIds);
};

export const getLocallySeenBadges = () => locallySeenBadgeStorage.get() ?? [];

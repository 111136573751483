import { isString, isUndefined } from "util/JavascriptUtility";

export type MetaData = {
  id: string;
  name: string;
  value: string;
  group?: string;
  type?: MetaTotalType;
};

export enum MetaTotalType {
  DELIVERY_FEE = "delivery_fee",
  DISCOUNT = "discount",
  FEE = "fee",
  SALES_TAX = "sales_tax",
  SUBTOTAL = "subtotal",
  TAX = "tax",
  TIP = "tip",
  TOTAL = "total",
}

const isMetaTotalType = (object: unknown): object is MetaTotalType => {
  const type = object as MetaTotalType;
  return !!type && Object.values(MetaTotalType).includes(type);
};

export const isMetaData = (object: unknown): object is MetaData => {
  const metaData = object as MetaData;
  return (
    !!metaData &&
    !!metaData.id &&
    !!metaData.name &&
    !!metaData.value &&
    (isUndefined(metaData.group) || isString(metaData.group)) &&
    (isUndefined(metaData.type) || isMetaTotalType(metaData.type))
  );
};

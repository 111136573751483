import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { OrderType } from "models/order/OrderType";
import { forwardRef } from "react";
import styles from "ui/app/GlobalNav/OrderNav/OrderInfoButton/OrderInfoButton.styles";
import Icon from "ui/components/Icon/Icon";

export type OrderInfoButtonProps = {
  address: string;
  isExpanded: boolean;
  orderType: OrderType;
  onClick?: () => void;
};

const OrderInfoButton = forwardRef<HTMLElement, OrderInfoButtonProps>(function OrderInfoButton(
  { address, isExpanded, onClick, orderType },
  ref
) {
  const theme = useTheme();
  const isExtraLargeOrGreater = useMediaQuery(theme.breakpoints.up("xl"));
  const direction = isExtraLargeOrGreater ? "column" : "row";
  const alignment = isExtraLargeOrGreater ? "flex-start" : "flex-end";
  const iconSize = isExtraLargeOrGreater ? "medium" : "small";
  const orderTypeText = orderType === OrderType.delivery ? "Delivery to" : "Pickup from";

  return (
    <Box ref={ref} sx={styles.container}>
      <Link component="button" onClick={onClick} sx={styles.button} underline="none">
        <Stack direction="row" alignItems="center" gap="0.5rem">
          <Icon icon="location" size={direction === "row" ? "medium" : undefined} />
          <Stack direction="row" alignItems="center">
            <Stack direction={direction} alignItems={alignment} gap={direction === "row" ? "0.25rem" : undefined}>
              <Typography variant="body2" color="grey.300" noWrap component="span">
                {orderTypeText}
              </Typography>
              <Typography variant="body2Bold" color="secondary.contrastText" component="span" textAlign="left" noWrap>
                {address}
                {isExpanded ? (
                  <KeyboardArrowUpIcon sx={styles.chevron} fontSize={iconSize} />
                ) : (
                  <KeyboardArrowDownIcon sx={styles.chevron} fontSize={iconSize} />
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Link>
    </Box>
  );
});

export default OrderInfoButton;

import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  addressContainer: {
    alignItems: "center",
    padding: (theme) => theme.spacing(3, 2),
  },
  changeLocationText: {
    fontSize: (theme) => `${theme.pxToRem(17)} !important`,
  },
  cityStateZip: (theme) => ({
    color: Colors.superBlack,
    fontSize: `${theme.pxToRem(17)} !important`,
    lineHeight: `${theme.pxToRem(24)} !important`,
  }),
  container: (theme) => ({
    [theme.breakpoints.up("md")]: {
      paddingRight: "1rem",
    },

    [theme.breakpoints.up("xl")]: {
      paddingLeft: "1.5rem",
      paddingRight: "0.5rem",
    },
  }),
  orderButton: (theme) => ({
    display: "none",

    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  }),
  popoverContainer: {
    minWidth: (theme) => theme.spacing(45),
  },
  popoverFooter: {
    alignItems: "center",
    background: Colors.gray100,
    flexDirection: "row",
    justifyContent: "center",
    gap: 1.5,
    padding: (theme) => theme.spacing(3, 0),
  },
  startOverButton: {
    fontSize: (theme) => `${theme.pxToRem(18)} !important`,
  },
  storeInfoButton: (theme) => ({
    "&:visited": {
      color: Colors.superBlack,
    },

    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(24),
    },
  }),
  streetAddress: (theme) => ({
    fontSize: `${theme.pxToRem(20)} !important`,
    lineHeight: `${theme.pxToRem(24)} !important`,
  }),
} as const satisfies MuiStyles;

export default styles;

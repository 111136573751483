import { ContactInfo } from "models/ContactInfo";
import { MetaData } from "models/MetaData";
import { CartItem } from "models/order/CartItem";
import Coupon from "models/order/Coupon";
import CustomField from "models/order/CustomField";
import { DeliveryInfo } from "models/order/DeliveryInfo";
import OrderFeatures from "models/order/OrderFeatures";
import { OrderType } from "models/order/OrderType";
import Tip from "models/order/Tip";
import { CashPaymentInfo } from "models/payment/CashPayment";
import CreditCardInfoResponse from "models/payment/CreditCardInfoResponse";
import { DigitalWalletDetails } from "models/payment/DigitalWallet";
import GiftCardInfoResponse from "models/payment/GiftCardInfoResponse";
import { SavedPaymentInfoResponse } from "models/payment/SavedPaymentInfoResponse";
import { RewardItem } from "models/rewards/Reward";
import { Store } from "models/store/Store";

export type OrderPayment = CashPaymentInfo | SavedPaymentInfoResponse | CreditCardInfoResponse | GiftCardInfoResponse;

export type Order = {
  id: string;
  items: CartItem[];
  store: Store;
  timeWanted?: string;
  totals: MetaData[];
  contactInfo?: ContactInfo;
  coupon?: Coupon;
  deliveryInfo?: DeliveryInfo;
  estimatedWaitTime?: string;
  orderType: OrderType;
  payment?: OrderPayment;
  features?: OrderFeatures[];
  specialInstructions?: string;
  tip?: Tip;
  supportsFullAddressCollectionCreditCard: boolean;
  appliedRewards?: RewardItem[];
  digitalWalletDetails?: {
    applePay?: DigitalWalletDetails;
    googlePay?: DigitalWalletDetails;
  };
  customFields?: CustomField[];
};

export const isOrder = (object: unknown): object is Order => {
  const order = object as Order;
  return (
    order != null &&
    order.id != null &&
    order.store != null &&
    order.items != null &&
    order.orderType != null &&
    order.totals != null
  );
};

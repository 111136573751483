import { Stack, Typography } from "@mui/material";
import { Control, UseFormRegister } from "react-hook-form";
import GroupOrderParticipantInvitesField from "ui/components/GroupOrderParticipantInvites/GroupOrderParticipantInvites";
import { StartGroupOrderSchema } from "ui/components/StartGroupOrderModal/StartGroupOrderModal.data";
import styles from "ui/components/StartGroupOrderModal/StartGroupOrderModal.styles";
import TextField from "ui/components/TextField";
import { FieldOptions } from "util/FieldOptions";

type WhosInvitedProps = {
  control: Control<StartGroupOrderSchema>;
  fieldOptions: FieldOptions<StartGroupOrderSchema>;
  savedParticipants: string[];
  register: UseFormRegister<StartGroupOrderSchema>;
};

const WhosInvited = ({ control, fieldOptions, savedParticipants, register }: WhosInvitedProps) => {
  return (
    <Stack gap={2}>
      <div>
        <Typography variant="h4" component="h2" sx={styles.sectionHeading}>
          Who&rsquo;s Invited?
        </Typography>
        <Typography>
          Type as many email addresses as you&apos;d like using the Enter/Return key between entries.
        </Typography>
      </div>
      <div>
        <GroupOrderParticipantInvitesField
          control={control}
          fieldOptions={fieldOptions}
          register={register}
          savedParticipants={savedParticipants}
        />
      </div>
      <TextField id="invitation-text" label="Invitation Text" optional={true} {...register("invitationText")} />
    </Stack>
  );
};

export default WhosInvited;

import { Box, Button, Fade, Link as MuiLink, Stack, Typography } from "@mui/material";
import useAnalytics from "hooks/useAnalytics";
import { observer } from "mobx-react-lite";
import { OrderType } from "models/order/OrderType";
import { useRef } from "react";
import { Link } from "react-router-dom";
import OrderInfoButton from "ui/app/GlobalNav/OrderNav/OrderInfoButton";
import styles from "ui/app/GlobalNav/OrderNav/OrderNav.styles";
import useViewModel from "ui/app/GlobalNav/OrderNav/OrderNavViewModel";
import NavPopover from "ui/components/NavPopover";
import { MainPagePath } from "ui/navigation/Pages";

const OrderNav = observer(() => {
  const {
    orderType,
    streetAddress,
    crossStreet,
    cityStateZip,
    storeInfoUrl,
    shouldShowPopover,
    shouldShowChangeLocationText,
    startOverLabel,
    handleClosePopover,
    handleStartOver,
    handleTogglePopover,
  } = useViewModel();
  const popoverAnchorRef = useRef<HTMLElement>(null);
  const { analytics } = useAnalytics();

  return (
    <Box sx={styles.container}>
      {!orderType || !streetAddress ? (
        <Button
          component={Link}
          to={MainPagePath.locations}
          sx={styles.orderButton}
          onClick={() => analytics.linkClick("Order Now", "Global Header")}
        >
          Order Now
        </Button>
      ) : (
        <>
          <Fade in={true} timeout={200}>
            <Box>
              <OrderInfoButton
                address={streetAddress}
                isExpanded={shouldShowPopover}
                onClick={handleTogglePopover}
                orderType={orderType}
                ref={popoverAnchorRef}
                aria-controls={shouldShowPopover ? "order-nav-menu" : undefined}
                aria-expanded={shouldShowPopover ? "true" : undefined}
                aria-haspopup="true"
              />
            </Box>
          </Fade>
          <NavPopover anchorEl={popoverAnchorRef.current} open={shouldShowPopover} onClose={handleClosePopover}>
            <Box sx={styles.popoverContainer}>
              <Stack sx={styles.addressContainer}>
                <Typography component="p" variant="h4" sx={styles.streetAddress}>
                  {streetAddress}
                </Typography>
                <Typography sx={styles.cityStateZip}>
                  {crossStreet}
                  <br />
                  {cityStateZip}
                </Typography>
                {orderType === OrderType.pickup && (
                  <MuiLink
                    target="_blank"
                    href={storeInfoUrl}
                    sx={styles.storeInfoButton}
                    onClick={() => analytics.linkClick("Store Info", "Global Header")}
                  >
                    Store Info
                  </MuiLink>
                )}
              </Stack>
              <Stack sx={styles.popoverFooter}>
                {shouldShowChangeLocationText && (
                  <Typography sx={styles.changeLocationText}>Need to change locations?</Typography>
                )}
                <Button role="link" onClick={handleStartOver} variant="text" sx={styles.startOverButton}>
                  {startOverLabel}
                </Button>
              </Stack>
            </Box>
          </NavPopover>
        </>
      )}
    </Box>
  );
});

export default OrderNav;

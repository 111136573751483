import MuiStyles from "ui/util/Styles";

const styles = {
  container: (theme) => ({
    width: "100%",
    height: "100%",
    position: "relative",

    //Splide Top Level Styles
    "& .splide": {
      display: "flex",
      flexDirection: "column",
    },

    // Show a margin at the bottom of the splide track for mobile
    // when pagination dots are not visible (not overflow)
    "& .splide:not(.is-overflow) .splide__track": {
      [theme.breakpoints.down("md")]: {
        marginBottom: "1rem",
      },
    },

    //Splide Arrow Styles
    "& .splide__arrow > svg": {
      fill: theme.palette.common.white,
    },

    "& .splide__arrow": {
      width: "4rem",
      height: "4rem",
      top: "50%",
      backgroundColor: "#E4002B",
      ":hover": {
        opacity: "unset",
      },
      opacity: "unset",
      ":disabled": {
        display: "none",
      },
    },
    "& .splide__arrow--next": {
      right: "2rem",
    },
    "& .splide__arrow--prev": {
      left: "-1.625rem",
    },
    "& .splide__arrow--hide": {
      display: "none",
    },

    //Splide Track Styles
    "& .splide__track": {
      overflowX: "clip",
      overflowY: "visible",
      height: "fit-content",
    },

    //Splide Pagination Styles
    "& .splide__pagination": {
      position: "unset",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    "& .splide__pagination__page": {
      opacity: 1,
    },
    "& .splide__pagination__page.is-active": {
      background: theme.palette.primary.main,
    },
  }),
} satisfies MuiStyles;

export default styles;

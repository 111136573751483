import { ProductDataTransformer } from "dataTransformers/ProductDataTransformer";
import { observer } from "mobx-react-lite";
import { createContext, ReactNode } from "react";
import ProductStore from "stores/menu/ProductStore";
import { useOrder } from "stores/order";
import RootStore from "stores/RootStore";

export const MenuContext = createContext<ProductStore | null>(null);

type StoreProviderProps = {
  children: ReactNode;
};

const MenuProvider = observer(({ children }: StoreProviderProps) => {
  const orderStore = useOrder();
  const store = new ProductStore(new ProductDataTransformer(RootStore.networker, RootStore.tokenManager, orderStore));
  return <MenuContext.Provider value={store}>{children}</MenuContext.Provider>;
});

export default MenuProvider;

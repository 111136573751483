import { isValidDate, isValidMonthDay } from "util/DateHelpers";

export class UserFirstLastNameValidations {
  /**
   * Matches on a minimum of 1 letter, a maximum of 30 characters, must begin and end with a letter, periods, apostrophes, and
   * spaces are allowed within name
   */

  /** Checks for invalid characters, does not check the length */
  static containsOnlyValidCharacters(value?: string): boolean {
    if (!value) return false;
    return /^[a-zA-Z '-.]*$/.test(value);
  }

  /** Checks that it begins and ends with a letter */
  static beginsAndEndsWithLetter(value?: string): boolean {
    if (!value) return false;
    if (value.length === 1) return /^[a-zA-Z]$/.test(value);
    return /^[a-zA-Z].*[a-zA-Z]$/.test(value);
  }

  /** Checks that it begins and ends with a letter */
  static meetsLengthRequirement(value?: string): boolean {
    if (!value) return false;
    return value.length > 0 && value.length <= 30;
  }

  /** Runs all name checks */
  static isValidName(value?: string): boolean {
    return (
      UserFirstLastNameValidations.containsOnlyValidCharacters(value) &&
      UserFirstLastNameValidations.beginsAndEndsWithLetter(value) &&
      UserFirstLastNameValidations.meetsLengthRequirement(value)
    );
  }
}

export class EmailValidations {
  /**
   * Validates email based on OWASP recommendations
   * https://github.com/OWASP/CheatSheetSeries/blob/5389a06b67a606f036aae4bf0fb7fa8094615a82/cheatsheets
   * /Input_Validation_Cheat_Sheet.md#email-address-validation
   *
   * Maximum length is 254 characters
   * Must not contain any of the characters: '`" or whitespace
   * Must contain a single @ symbol
   * The local part (before the @ symbol) must be a minimum of 1 and maximum of 63 characters in length
   * The domain (after the @ symbol) contains only letters, numbers, hyphens, and periods
   */

  static meetsLengthRequirement(value?: string): boolean {
    if (!value) return false;
    return value.length < 255;
  }

  static meetsCharacterRequirements(value?: string): boolean {
    if (!value) return false;
    return !/['`"\s]/.test(value);
  }

  static hasAtSymbol(value?: string): boolean {
    if (!value) return false;
    return (value.match(/@/g) || []).length === 1;
  }

  static meetsLocalRequirements(value?: string): boolean {
    if (!value) return false;
    return /^[\S]{1,63}@/.test(value);
  }

  static meetsDomainRequirement(value?: string): boolean {
    if (!value) return false;
    return /@[a-zA-Z0-9-.]+$/.test(value);
  }

  static isValidEmail(value?: string): boolean {
    if (!value) return false;
    return (
      EmailValidations.meetsLengthRequirement(value) &&
      EmailValidations.meetsCharacterRequirements(value) &&
      EmailValidations.hasAtSymbol(value) &&
      EmailValidations.meetsLocalRequirements(value) &&
      EmailValidations.meetsDomainRequirement(value)
    );
  }
}

export class PhoneValidations {
  static meetsLengthRequirement(value?: string): boolean {
    if (!value) return false;
    const trimmedPhone = value.replace(/\D/g, "");
    return trimmedPhone.length === 10 || trimmedPhone.length === 11;
  }

  static meetsCharacterRequirements(value?: string): boolean {
    if (!value) return false;
    return /^[\d- )(]*$/.test(value);
  }

  static isValidPhone(value?: string): boolean {
    if (!value) return false;
    // Not doing the formatting requirement since it's not needed to be a valid phone
    return PhoneValidations.meetsLengthRequirement(value);
  }
}

export class AddressValidations {
  // Used for address line 1, address line 2, city
  static meetsCharacterRequirements(value?: string): boolean {
    if (!value) return false;
    return /^[a-zA-Z0-9' .-]+$/g.test(value);
  }

  static meetsBeginningRequirements(value?: string): boolean {
    if (!value) return false;
    return /^[a-zA-Z0-9]/g.test(value);
  }

  static meetsEndRequirements(value?: string): boolean {
    if (!value) return false;
    return /[a-zA-Z0-9.']$/g.test(value);
  }

  static isValidAddressLine(value?: string): boolean {
    return (
      AddressValidations.meetsCharacterRequirements(value) &&
      AddressValidations.meetsBeginningRequirements(value) &&
      AddressValidations.meetsEndRequirements(value)
    );
  }

  static isValidZipCode(value?: string): boolean {
    if (!value) return false;
    const usFormat = /^[0-9]{5}(?:-[0-9]{4})?$/g;
    const canadianFormat = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/g;
    return usFormat.test(value) || canadianFormat.test(value);
  }

  static isValidUsShortZipCode(value?: string): boolean {
    if (!value) return false;
    const usShortFormat = /^[0-9]{5}?$/g;
    return usShortFormat.test(value);
  }
}

export class DateValidations {
  static isValidDate(value?: string): boolean {
    if (!value) return false;
    return isValidDate(value);
  }
}

export class BirthdayValidations {
  static isValidBirthday(value?: string) {
    return isValidMonthDay(value);
  }
}

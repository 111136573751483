import MuiStyles from "ui/util/Styles";

const styles: MuiStyles = {
  chevron: {
    marginLeft: "0.25rem",
    verticalAlign: "bottom",
  },
  button: (theme) => ({
    textDecoration: "none",

    "&:focus-visible": {
      borderColor: "transparent",
      outline: `0.25rem solid ${theme.palette.primary.light}`,
    },

    "@supports not selector(:focus-visible)": {
      "&:focus": {
        borderColor: "transparent",
        outline: `0.25rem solid ${theme.palette.primary.light}`,
      },
    },

    ".MuiSvgIcon-root": {
      color: (theme) => theme.palette.grey[400],
    },
  }),
  container: (theme) => ({
    borderTop: `0.0625rem solid ${theme.palette.grey[600]}`,
    display: "flex",
    justifyContent: "center",
    paddingTop: "1rem",
    paddingBottom: "1rem",

    [theme.breakpoints.up("xl")]: {
      borderTop: 0,
      justifyContent: "normal",
      marginTop: 0,
      paddingBottom: 0,
      paddingTop: 0,
    },
  }),
};

export default styles;

import { lazy } from "react";
import { Route } from "react-router-dom";
import Layout from "ui/app/Layout";
import AuthenticationLayout from "ui/app/MinimalLayout";
import { GroupOrderPagePath } from "ui/navigation/Pages";
import ProtectedRoute from "ui/navigation/routes/ProtectedRoute";

const GroupOrderSubmissionError = lazy(() => import("ui/screens/groupOrder/GroupOrderSubmissionError"));
const JoinGroupOrderScreen = lazy(() => import("ui/screens/groupOrder/JoinGroupOrderScreen"));
const ResumeGroupOrder = lazy(() => import("ui/screens/groupOrder/ResumeGroupOrder"));

const GroupOrderRoutes = (
  <>
    <Route element={<Layout />}>
      <Route element={<ProtectedRoute />}>
        <Route path={GroupOrderPagePath.base} element={<ResumeGroupOrder />} />
      </Route>
      <Route path={GroupOrderPagePath.submissionError} element={<GroupOrderSubmissionError />} />
    </Route>
    <Route element={<AuthenticationLayout />}>
      <Route path={GroupOrderPagePath.join} element={<JoinGroupOrderScreen />} />
    </Route>
  </>
);

export default GroupOrderRoutes;

import { useContext } from "react";
import { BadgeModalContext } from "stores/badgeModal/BadgeModalProvider";

const useBadgeModal = () => {
  const context = useContext(BadgeModalContext);
  if (!context) {
    throw new Error("useBadgeModal must be used within a BadgeModalProvider.");
  }
  return context;
};

export default useBadgeModal;

import MuiStyles from "ui/util/Styles";

const styles = {
  logo: {
    height: "168px",
    width: "168px",
  },
  container: (theme) => ({
    "a:mobileView": {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    background: theme.palette.common.black,
    width: "100%",
    margin: "0 auto",
    padding: "1rem",
    [theme.breakpoints.up("lg")]: {
      padding: "2rem 3rem",
    },
  }),
  siteLinkItems: {
    marginTop: "1rem",
  },
  accordion: (theme) => ({
    backgroundColor: theme.palette.common.black,
    borderBottom: (theme) => `1px solid ${theme.palette.grey[600]}`,
    marginBottom: "3px",
    a: {
      textDecoration: "none",
      margin: "7px 0",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    ".Mui-focusVisible": {
      outline: "4px solid",
      outlineColor: theme.palette.primary.light,
    },
    ":last-child": {
      borderBottom: "none",
      marginBottom: "2rem",
    },
    svg: {
      color: theme.palette.grey[300],
    },
    ".MuiAccordionDetails-root": {
      paddingLeft: "0.5rem",
    },
    ".MuiAccordionSummary-content": {
      paddingLeft: "0.5rem",
    },
    "& .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper": {
      marginRight: "0.5rem",
    },
  }),
  title: (theme) => ({
    borderBottom: "1px solid",
    borderColor: theme.palette.grey[600],
  }),
  desktopSiteLinks: (theme) => ({
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      marginBottom: "2rem",
    },
  }),
  mobileSiteLinks: (theme) => ({
    marginBottom: "2rem",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  }),
  siteLinks: (theme) => ({
    ".MuiLink-root": {
      borderBottomColor: "transparent",
      color: theme.palette.grey[300],
    },
    ".MuiLink-root.imagelink:hover": {
      border: "none",
    },
    ".MuiLink-root:visited": {
      color: theme.palette.grey[300],
    },
    ".MuiLink-root:focus-visible, .MuiLink-root.Mui-focusVisible": {
      borderColor: "transparent",
      borderWidth: "0 0 1px 0",
      outline: `0.25rem solid ${theme.palette.primary.light}`,
      padding: "0.0625rem",
    },
    ".MuiLink-root:hover": {
      borderBottomColor: theme.palette.primary.main,
      color: theme.palette.grey[300],
    },
    ".MuiLink-root:active": {
      color: "white",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
  }),
  secodaryLinksTitle: {
    marginTop: "1rem",
  },
  siteMap: (theme) => ({
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      gap: "4.5rem",
      paddingLeft: "3rem",
      marginLeft: "3rem",
      borderLeft: "1px solid",
      borderColor: theme.palette.grey[600],
    },
  }),
  column: {
    li: {
      margin: "0 0 1rem 0",

      "&:last-child": {
        marginBottom: 0,
      },
    },
    a: {
      textDecoration: "none",
      typography: "bodySmall",
      lineHeight: "1.25rem",
    },
  },
  appStoreLinks: {
    alignItems: "normal",
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    marginLeft: "auto",
  },
  appStoreLinkApple: {
    height: "2.5rem",
    width: "7.5rem",
  },
  appStoreLinkGoogle: {
    height: "2.5rem",
    width: "8.375rem",
  },
  secondaryLinks: (theme) => ({
    padding: "1.5rem 0 0 0",
    marginBottom: 0,
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "wrap",
    li: {
      paddingTop: 0,
    },
    ".MuiLink-root": {
      borderBottomColor: "transparent",
      color: theme.palette.grey[300],
    },
    ".MuiLink-root:focus-visible, .MuiLink-root.Mui-focusVisible": {
      borderColor: "transparent",
      borderWidth: "0 0 1px 0",
      outline: `0.25rem solid ${theme.palette.primary.light}`,
      padding: "0.0625rem",
    },
    ".MuiLink-root:hover": {
      borderBottomColor: theme.palette.primary.main,
      color: theme.palette.grey[300],
    },
    ".MuiLink-root:active": {
      color: "white",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0",
      display: "flex",
      marginTop: "2rem",
      marginBottom: "1rem",
      ul: {
        padding: 0,
      },
    },
  }),
  secondaryLink: (theme) => ({
    display: "flex",
    marginBottom: "1rem",
    width: "50%",
    a: { textDecoration: "none" },
    [theme.breakpoints.up("lg")]: {
      marginRight: 0,
      marginBottom: 0,
      width: "unset",
    },
  }),
  secondaryLinksDivider: (theme) => ({
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "inline-block",
      margin: "0 1rem",
      borderColor: theme.palette.grey[600],
    },
  }),
  mobilePadding: (theme) => ({
    [theme.breakpoints.down("md")]: {
      paddingTop: "2.5rem",
    },
  }),
  divider: (theme) => ({
    marginLeft: 0,
    marginRight: 0,
    borderColor: theme.palette.grey[600],
  }),
  linksAndSocial: (theme) => ({
    display: "block",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  }),
  socialLinks: (theme) => ({
    display: "flex",
    gap: "2.5rem",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end",
      marginTop: "1.5rem",
      gap: "2rem",
    },
  }),
  copyright: (theme) => ({
    color: theme.palette.grey[300],
  }),
  recaptchaText: (theme) => ({
    color: theme.palette.grey[300],

    "& a": {
      color: theme.palette.grey[300],
      fontWeight: 370,
      textDecoration: "none",
    },
  }),
} as const satisfies MuiStyles;

export default styles;

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx";
import IconCheck from "assets/checkbox/check.svg";
import React, { Ref } from "react";
import { ReactNode } from "react";
import styles from "ui/components/Checkbox/Checkbox.styles";
import Icon from "ui/components/Icon/Icon";
import multiStyles from "ui/components/MultiSelectCheckbox/MultiSelectCheckbox.styles";

export enum CheckboxVariant {
  Basic = "basic",
  Outlined = "outlined",
}

export const CheckboxIcon = () => {
  return (
    <Box className="checkbox-icon">
      <img src={IconCheck} aria-hidden="true" />
    </Box>
  );
};

export type CheckboxControlProps = Omit<FormControlLabelProps, "control" | "label"> & {
  label: string | ReactNode;
  sublabel?: string;
  sublabelTwo?: string;
  variant?: CheckboxVariant;
  tooltipText?: string;
  capitalizeText?: boolean;
  multiSelect?: boolean;
  id?: string;
  containerSx?: SxProps<Theme>;
};

export const CheckboxControl = React.forwardRef(function CheckboxControl(
  {
    label,
    sublabel,
    sublabelTwo,
    tooltipText,
    id,
    multiSelect,
    variant = CheckboxVariant.Outlined,
    capitalizeText = true,
    sx,
    containerSx,
    ...formControlLabelProps
  }: CheckboxControlProps,
  ref?: Ref<unknown>
) {
  const isSelected = !!formControlLabelProps.checked;
  return (
    <Box sx={[styles.container, ...(containerSx ? [containerSx as SystemStyleObject<Theme>] : [])]}>
      <FormControlLabel
        control={<Checkbox id={id} />}
        inputRef={ref}
        label={
          <Box sx={styles.root}>
            <Box sx={styles.content}>
              <Typography
                component="span"
                sx={[styles.checkboxTitle, capitalizeText ? styles.capitalizeTitle : null]}
                variant={variant === CheckboxVariant.Outlined ? "formFieldLabel" : "formFieldText"}
              >
                {label}
              </Typography>

              <Box component="span" sx={styles.sublabels}>
                {sublabel ? (
                  <Typography
                    component="span"
                    variant={variant === CheckboxVariant.Outlined ? "bodySmallBold" : "formFieldText"}
                    sx={styles.sublabel}
                  >
                    {sublabel}
                  </Typography>
                ) : null}

                {sublabel && sublabelTwo ? (
                  <>
                    <Divider sx={styles.divider} orientation="vertical" />
                  </>
                ) : null}

                {sublabelTwo ? (
                  <Typography
                    component="span"
                    variant={variant === CheckboxVariant.Outlined ? "bodySmall" : "formFieldText"}
                    sx={styles.sublabelTwo}
                  >
                    {sublabelTwo}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          </Box>
        }
        sx={[
          styles.formControlLabel,
          tooltipText ? styles.maxWidth : null,
          variant === CheckboxVariant.Outlined ? styles.outlined : styles.basic,
          multiSelect ? multiStyles.multiselect : null,
          ...(sx ? [sx as SystemStyleObject<Theme>] : []),
        ]}
        className={`checkbox-${variant}${isSelected ? " selected" : ""}`}
        {...formControlLabelProps}
      />
      {tooltipText ? (
        <Tooltip
          PopperProps={{ sx: styles.tooltip }}
          arrow
          placement="top"
          title={
            <Typography sx={styles.tooltipText} variant="bodySmall">
              {tooltipText}
            </Typography>
          }
        >
          <Box sx={styles.icon} tabIndex={0} aria-label="more info">
            <Icon icon={"info"} />
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );
});

export default CheckboxControl;

import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  cartContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    overflowY: "scroll",
  },
  cartDrawerTitle: (theme) => ({
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
    },
  }),
  closeIcon: (theme) => ({
    color: Colors.superBlack,
    marginRight: theme.spacing(-1.5),
    padding: theme.spacing(1.5),
  }),
  cta: {
    flexGrow: 1,
  },
  ctas: (theme) => ({
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    display: "flex",
    flexDirection: "row",
    gap: 2,
    justifyContent: "space-between",
    p: 2,
    boxShadow: "0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.15)",
  }),
  drawer: (theme) => ({
    "& .MuiDrawer-paper": {
      width: "100%",

      [theme.breakpoints.up("md")]: {
        width: theme.spacing(53),
      },
    },
  }),
  empty: (theme) => ({
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "1.5rem",
    justifyContent: "center",
    margin: theme.spacing(6),
    textAlign: "center",
  }),
  emptyBodyText: (theme) => ({
    marginTop: theme.spacing(0.5),
  }),
  header: (theme) => ({
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(0, 2),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(1.5),
  }),
  groupOrderButton: {
    alignItems: "center",
    flexDirection: "row",
    gap: "0.5rem",
  },
  addParticipantsButton: (theme) => ({
    alignItems: "center",
    flexDirection: "row",
    gap: "0.5rem",
    minHeight: theme.pxToRem(50),
    background: theme.palette.grey[100],
    padding: theme.spacing(2, 0, 2, 2),
  }),
  groupOrderButtonWrapper: {
    margin: "1rem 1rem 0.5rem",
  },
} as const satisfies MuiStyles;

export default styles;

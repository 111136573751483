export enum PaymentType {
  cash = "cash",
  credit = "debit-credit",
  digitalWallet = "digital-wallet",
  giftCard = "gift-card",
  savedPayment = "saved-payment",
}

export const isPaymentType = (object: unknown): object is PaymentType => {
  return (
    object === PaymentType.cash ||
    object === PaymentType.credit ||
    object === PaymentType.digitalWallet ||
    object === PaymentType.giftCard ||
    object === PaymentType.savedPayment
  );
};

/** Helper so title is always the same */
export const titleForPaymentType = (type: PaymentType) => {
  switch (type) {
    case PaymentType.cash:
      return "Cash";
    case PaymentType.credit:
      return "Credit or Debit Card";
    case PaymentType.giftCard:
      return "Gift Card";
    case PaymentType.savedPayment:
      return "Saved Payment";
  }
};

import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  // NavUser
  navUserButton: (theme) => ({
    marginRight: "1rem",

    "&:focus-visible": {
      borderColor: "transparent",
      outline: `0.25rem solid ${theme.palette.primary.light}`,
    },

    "@supports not selector(:focus-visible)": {
      "&:focus": {
        borderColor: "transparent",
        outline: `0.25rem solid ${theme.palette.primary.light}`,
      },
    },

    ".MuiSvgIcon-root": {
      color: theme.palette.grey[400],
    },
  }),
  buttonMessage: (theme) => ({
    fontWeight: theme.typography.body2.fontWeight,

    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    whiteSpace: "nowrap",

    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },
  }),
  buttonFirstName: (theme) => ({
    fontWeight: theme.typography.body2Bold.fontWeight,

    color: Colors.brightWhite,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    whiteSpace: "nowrap",

    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },

    "> svg": {
      marginLeft: "0.25rem",
      verticalAlign: "-0.375rem",
    },
  }),

  listItem: {
    flexDirection: "column",
    padding: "0",
    textAlign: "center",
    width: "13.5rem",

    ".MuiListItemText-primary": {
      border: "0.0625rem solid transparent",
      color: (theme) => theme.palette.secondary.main,
      margin: "1rem 0",
    },

    "&:hover": {
      background: Colors.brightWhite,

      ".MuiListItemText-primary": {
        textDecoration: "underline",
        textDecorationColor: (theme) => theme.palette.primary.main,
        textUnderlineOffset: "0.25rem",
      },
    },

    "&:active, &.Mui-active": {
      background: Colors.brightWhite,

      ".MuiListItemText-primary": {
        color: (theme) => theme.palette.primary.main,
      },
    },

    "&:focus-visible, &.Mui-focusVisible": {
      background: Colors.brightWhite,
      ".MuiListItemText-primary": {
        border: (theme) => `0.0625rem solid ${theme.palette.primary.main}`,
      },
    },
  },
  listItemSecondary: {
    background: (theme) => theme.palette.grey[100],

    "&:hover": {
      background: (theme) => theme.palette.grey[100],
    },

    "&:active, &.Mui-active": {
      background: (theme) => theme.palette.grey[100],
    },

    "&:focus-visible, &.Mui-focusVisible": {
      background: (theme) => theme.palette.grey[100],
    },
  },
  listDivider: {
    display: "block",
    margin: "0 1rem",
    width: "calc(100% - 32px);",
  },
} as const satisfies MuiStyles;

export default styles;

import { Control, Controller, FieldValues, Path, UseFormRegister } from "react-hook-form";
import Checkbox, { CheckboxVariant } from "ui/components/Checkbox";
import MultiSelectComboBox from "ui/components/MultiSelectComboBox";
import { FieldOptions } from "util/FieldOptions";

type GroupOrderParticipantInvitesFieldProps<T extends FieldValues> = {
  control: Control<T>;
  fieldOptions: FieldOptions<T>;
  savedParticipants: string[];
  register: UseFormRegister<T>;
};

const GroupOrderParticipantInvitesField = <T extends FieldValues>({
  control,
  fieldOptions,
  savedParticipants,
  register,
}: GroupOrderParticipantInvitesFieldProps<T>) => {
  return (
    <>
      <Controller
        control={control}
        name={"participantEmails" as Path<T>}
        render={({ field, fieldState: { error } }) => (
          <MultiSelectComboBox
            errorText={error?.message}
            label="Invitees"
            options={savedParticipants}
            placeholder="Email address(es)"
            required
            {...field}
          />
        )}
        rules={fieldOptions.participantEmails}
      />
      <Checkbox
        capitalizeText={false}
        label="Save these contacts for use in future group orders"
        variant={CheckboxVariant.Basic}
        {...register("saveParticipantEmails" as Path<T>)}
      />
    </>
  );
};

export default GroupOrderParticipantInvitesField;

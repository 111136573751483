import { FieldOptions } from "util/FieldOptions";

export type AddParticipantsSchema = {
  participantEmails: string[];
  saveParticipantEmails?: boolean;
};

export const fieldOptions: FieldOptions<AddParticipantsSchema> = {
  participantEmails: {
    required: "This field is required",
  },
};

export type OrderUpsellGroupItem = {
  id: string;
  name: string;
  cost: string;
  shortDescription: string;
  minQuantity: number;
  maxQuantity: number;
};

export type OrderUpsellGroup = {
  title: string;
  items: OrderUpsellGroupItem[];
};

export type OrderUpsellResponse = {
  groups: OrderUpsellGroup[];
};

export const isOrderUpsellResponse = (object: unknown): object is OrderUpsellResponse => {
  const upsellResponse = object as OrderUpsellResponse;

  return !!upsellResponse && upsellResponse.groups && Array.isArray(upsellResponse.groups);
};

import "ui/App.css";
import { ThemeProvider } from "@mui/material";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import branch from "branch-sdk";
import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AlertProvider } from "stores/alert";
import { BadgeModalProvider } from "stores/badgeModal";
import { CartProvider } from "stores/cart";
import { MenuProvider } from "stores/menu";
import { OrderProvider } from "stores/order";
import { StoreProvider } from "stores/store";
import StoreManagerProvider from "stores/StoreManagerProvider";
import { UserProvider } from "stores/user";
import Layout from "ui/app/Layout";
import { AppSize } from "ui/AppSize";
import ErrorBoundary from "ui/components/ErrorBoundary";
import SomethingWentWrong from "ui/components/ErrorBoundary/SomethingWentWrong";
import Spinner from "ui/components/Spinner";
import GlobalStyle from "ui/GlobalStyle";
import { MainPagePath } from "ui/navigation/Pages";
import AccountRoutes, { MyFavesRoutes } from "ui/navigation/routes/AccountRoutes";
import AuthenticationRoutes from "ui/navigation/routes/AuthenticationRoutes";
import CheckoutRoutes from "ui/navigation/routes/CheckoutRoutes";
import GroupOrderRoutes from "ui/navigation/routes/GroupOrderRoutes";
import InfoRoutes from "ui/navigation/routes/InfoRoutes";
import MenuRoutes from "ui/navigation/routes/MenuRoutes";
import muiTheme from "ui/theme/muiTheme";
import ScrollToTop from "ui/util/ScrollToTop";
import MParticle from "util/MParticle";
import { getOptimizelyClient, getOptimizelyUser } from "util/Optimizely";

const DocumentRoute = lazy(() => import("ui/navigation/routes/DocumentRoute"));
const HomeScreenView = lazy(() => import("ui/screens/HomeScreen"));
const LocationsScreen = lazy(() => import("ui/screens/LocationsScreen"));
const PageNotFound = lazy(() => import("ui/components/Error/PageNotFound"));

const App = () => {
  useEffect(() => {
    MParticle.init();
  }, []);

  // bootstrap AppSize
  useEffect(() => {
    AppSize.start(window, document);
  }, []);

  // Initialize the Branch SDK
  useEffect(() => {
    if (!Env.BranchApiKey) return;
    branch.init(Env.BranchApiKey);
  }, []);

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={muiTheme}>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <OptimizelyProvider optimizely={getOptimizelyClient()} user={getOptimizelyUser()} timeout={500}>
              <AlertProvider>
                <CartProvider>
                  <OrderProvider>
                    <StoreProvider>
                      <MenuProvider>
                        <UserProvider>
                          <BadgeModalProvider>
                            <StoreManagerProvider>
                              <BrowserRouter>
                                <ScrollToTop />
                                <Routes>
                                  <Route element={<Layout />}>
                                    <Route index element={<HomeScreenView />} />
                                    {AccountRoutes}
                                    {CheckoutRoutes}
                                    {MenuRoutes}
                                    {InfoRoutes}
                                    {MyFavesRoutes}
                                  </Route>
                                  <Route element={<Layout fullWidth />}>
                                    <Route path={MainPagePath.locations} element={<LocationsScreen />} />
                                  </Route>
                                  <Route path={MainPagePath.documents} element={<DocumentRoute />} />

                                  {AuthenticationRoutes}
                                  {GroupOrderRoutes}

                                  {/* Error routes */}
                                  <Route element={<Layout />}>
                                    <Route path={MainPagePath.error} element={<SomethingWentWrong />} />
                                    <Route path={MainPagePath.notFound} element={<PageNotFound />} />
                                    <Route path="*" element={<Navigate to={MainPagePath.home} replace />} />
                                  </Route>
                                </Routes>
                              </BrowserRouter>
                            </StoreManagerProvider>
                          </BadgeModalProvider>
                        </UserProvider>
                      </MenuProvider>
                    </StoreProvider>
                  </OrderProvider>
                </CartProvider>
              </AlertProvider>
            </OptimizelyProvider>
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
};

export default App;

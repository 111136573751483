import { Box, BoxProps, Typography } from "@mui/material";
import { MetaData } from "models/MetaData";
import styles from "ui/components/OrderSummary/OrderSummary.styles";

export type LedgerItem = Pick<MetaData, "id" | "name" | "value">;

type OrderSummaryProps = {
  ledgerItems: LedgerItem[];
  total: string;
} & BoxProps;

const OrderSummary = ({ ledgerItems, total, sx }: OrderSummaryProps) => {
  const additionalStyles = Array.isArray(sx) ? sx : typeof sx === "object" || typeof sx === "function" ? [sx] : [];

  return (
    <Box sx={[styles.container, ...additionalStyles]} aria-label="order summary">
      <Box component="ul" sx={styles.ledger}>
        {ledgerItems.map(({ id, name, value }) => (
          <Box component="li" key={name} sx={styles.ledgerItem}>
            <Typography component="span" variant="body2" sx={styles.ledgerItemLabel} id={`${id}-label`}>
              {name}
            </Typography>
            <Typography component="span" variant="body2" sx={styles.ledgerItemValue} aria-labelledby={`${id}-label`}>
              {value}
            </Typography>
          </Box>
        ))}
        <Box component="li" sx={styles.ledgerItem}>
          <Typography component="span" variant="body1Bold" sx={styles.ledgerItemLabel} id="total-label">
            Total
          </Typography>
          <Typography component="span" variant="body1Bold" sx={styles.ledgerItemValue} aria-labelledby="total-label">
            {total}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSummary;

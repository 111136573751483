import { createContext, PropsWithChildren } from "react";
import { BadgeModalManager } from "stores/badgeModal";
import useBadgeModalManager from "stores/badgeModal/useBadgeModalManager";

export const BadgeModalContext = createContext<BadgeModalManager | null>(null);

export const BadgeModalProvider = ({ children }: PropsWithChildren) => {
  const badgeModalManager = useBadgeModalManager();
  return <BadgeModalContext.Provider value={badgeModalManager}>{children}</BadgeModalContext.Provider>;
};

export default BadgeModalProvider;

import { Options } from "@splidejs/react-splide";

export const defaultOptions: Options = {
  paginationKeyboard: true,
  keyboard: true,
  padding: { right: "20%" },
};

type SlideComponent = {
  slide: HTMLElement;
};

export const isSlideComponent = (object: unknown): object is SlideComponent => {
  return typeof object === "object" && typeof (object as SlideComponent).slide === "object";
};

export const handleVisible = (slideComponent: unknown, onImpression?: (productName?: string | null) => void) => {
  if (!isSlideComponent(slideComponent)) return;

  const { slide } = slideComponent;

  const productName = slide.querySelector(".productName")?.textContent;
  if (onImpression) onImpression(productName);

  if (slide.firstChild && slide.firstChild.nodeName === "A") {
    const link = slide.firstChild as HTMLAnchorElement;
    link.focus();
    return;
  }

  slide.focus();
};
